import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { IAccessToken } from '../models/IAccessToken';
import { ApiClient } from './apiClient.service';

@Injectable()
export class AccessTokenService {
    basePath = '/accessToken';

    constructor(private apiClient: ApiClient) {}

    public createAccessToken(userCredentials: unknown): Observable<IAccessToken> {
        const path = `${this.basePath}`;
        return this.apiClient.post(path, userCredentials);
    }

    public createAccessTokenForClientId(clientId: string, scope: string): Observable<IAccessToken> {
        const path = `${this.basePath}/${clientId}?scope=${scope}`;
        return this.apiClient.post(path, {});
    }

    public logout(): Observable<void> {
        const path = `${this.basePath}`;
        return this.apiClient.delete(path);
    }
}
